import React from 'react'
import styled from 'styled-components';
import useForm from '../hooks/useForm'
import { validateReview } from '../hooks/validate';
import { Warning } from '../assetsjs/index'
import { navigate } from 'gatsby';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ReviewForm = ({ inputTitles, message }) => {
  const { values, handleChange, handleSubmit, errors } = useForm(submit, validateReview)

  function submit() {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'reviews',
        ...values,
      }),

    })
      .then(() => navigate("/thankyou", { replace: false, state: "reviews" }))
      .catch((error) => alert(error))
  }

  return (
    <S.ReviewForm
      noValidate
      name="reviews"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="reviews" />
      <S.ListContainer>
        <li>
          <p>{message}</p>
        </li>
        {inputTitles.map(({ name, type }) => {
          const _name = name.toLowerCase().replace(/\s+/g, '')
          return (
            <li
              key={name}
              className={errors[_name] && 'error'}
            >
              <label
                htmlFor={_name}
                type={type}
                style={{
                  alignItems: type === 'textarea' && 'baseline',
                  paddingTop: type === 'textarea' && 18
                }}
              >
                {name}:
              </label>
              <S.Input
                as={type !== 'textarea' ? null : type}
                type={type !== 'textarea' ? type : null}
                id={_name}
                name={_name}
                onChange={handleChange}
                value={values[_name]}
                className={errors[_name] && 'error'}
                required
              />

              {((errors[_name] && _name === Object.keys(values)[0]) || errors[_name]) && (
                <S.ErrorMessage>
                  <Warning /><span>{errors[_name]}</span>
                </S.ErrorMessage>
              )}
            </li>
          )
        })}
        <li>
          <button aria-label="Send" type="submit" children="Send" />
        </li>
      </S.ListContainer>

      {Object.values(errors).some(e => e !== '') &&
        <div>
          <Warning icon='#ffffff' sign='#e30613' />
          <span>There are invalid fields, please check these and try again</span>
        </div>
      }

    </S.ReviewForm>
  )
}

const S = {
  ReviewForm: styled.form`
    > div {
      text-align: center;
      color: var(--primary-two);
      display: flex;
      font-weight: var(--bolder);
      align-items: center;
      padding-top: 1rem;
      font-size: 0.75rem;
      > span {
        margin-top: 1.5px;
        padding-left: 0.7rem;
      }
    }
  `,
  ListContainer: styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.4rem;
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    transition: all .3s ease;
    > li {
      display: grid;
      border-radius: 12px;
      border: 2px solid var(--main-text);
      font-size: 1rem;
      grid-template-columns: 110px 1fr;
      justify-items: start;
      :first-child {
        border: none;
        text-align: left;
        margin-bottom: 2rem;
        grid-template-columns: 1fr;
      }
      :last-of-type {
        grid-template-columns: 1fr;
        justify-self: start;
        width: 50%;
        padding: 0;
        margin-bottom: 0;
        border: none;
      }
      > label {
        height: 100%;
        width: 100%;
        display: grid;
        align-items: center;
        padding-left: 1.1rem;
        font-weight: var(--bold);
        cursor: text;
      }
      > button {
        background: transparent;
        border: none;
        padding: 1rem;
        color: var(--main-text);
        width: 100%;
        border-radius: 12px;
        border: 2px solid var(--main-text);
        font-size: 1.1rem;
        font-weight: var(--bolder);
        transition: box-shadow 0.3s ease;
        transition: transform 0.3s ease;
        :hover,
        :focus {
          background: var(--main-text);
          color: white;
          cursor: pointer;
          transform: translate(0, -2px);
          box-shadow: var(--shadow-one);
        }
      }
    }
    
    
    @media (min-width: 440px) {
      > li {
        grid-template-columns: 150px 1fr;
        > label {
          padding-left: 1.8rem;
        }
      }
    }
    @media (min-width: 980px) {
      grid-template-columns: repeat(6, 1fr);
      > li {
        grid-column: 1 / span 4;
        :last-child {
          justify-self: start;
          width: auto;
          min-width: 250px;
        }
      }
    }
  `,
  Input: styled.input`
    background: transparent;
    color: var(--main-text);
    border: none;
    width: 100%;
    padding: 1rem;
    font-weight: 400;
    display: inline-block;
    border-radius: 0 10px 10px 0;
    box-sizing: border-box;
    resize: none;
    height: ${({ as }) => as && '300px'};
    :hover,
    :focus {
      background: rgba(var(--primary-four-raw),0.1);
    }
    p {
      display: none;
    }
    .show {
      display: block;
    }
    .hide {
      display: none;
    }
    &.error {
      border-radius: 0 10px 0 0;
    }
  `,
  ErrorMessage: styled.div`
    grid-column: 1 / -1;
    width: 100%;
    padding: 0.1rem 1.8rem;
    background: var(--primary-one);
    color: var(--primary-two);
    font-weight: var(--bolder);
    font-size: 0.7rem;
    border-radius: 0 0 8px 8px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 0.7rem;
    > span {
      margin-top: 1.5px;
    }
  `
}

export default ReviewForm
